@import '../css/html5-boilerplate';

/* ==========================================================================
   Own styles
   ========================================================================== */

/* own reset */
$font-main: 'Lato', sans-serif;
$color-font-main: #333;
$color-first: #43c14b;
$color-second: #ff530d;
$color-dark-light: #444;
$color-line-border: #ccc;
$color-success: #43c14b;
$color-error: red;
$color-notice: blue;
$color-main-header: #e6e6e6;
$color-white: #fff;

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}
@mixin transform-scale($scale) {
  -webkit-transform: scale($scale);
  -moz-transform: scale($scale);
  -ms-transform: scale($scale);
  -o-transform: scale($scale);
  transform: scale($scale);
}
@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

body {
  font-family: $font-main;
  color: $color-font-main;
}
a, a:link, a:visited {
  text-decoration: none;
  outline: none;
  cursor: pointer;
  color: inherit;
}
a:hover {
  text-decoration: none;
}
ul, ol {
  padding: 0;
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  list-style: none outside none;
}
img {
  vertical-align: middle;
  max-width: 100%;
}
textarea {
  resize: vertical;
}

/* flash boxes */
.flash-box {
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  padding: 20px 10px;
  border: 1px solid $color-white;
  margin-top: 20px;
  @include border-radius(15px);
}
.flash-notice-box {
  background-color: $color-notice;
  color: $color-white;
}
.flash-success-box {
  background-color: $color-second;
  color: $color-white;
}
.flash-error-box {
  background-color: $color-error;
  color: $color-white;
}

#main-content {
  h1 {
    border-bottom: 1px solid $color-line-border;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
}

/* header */
#main-header {
  border-bottom: 1px solid $color-main-header;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $color-white;
  z-index: 4;
  #logo {
    height: 52px;
    line-height: 56px;
  }
}
#main-menu {
  list-style-type: none;
  font-size: 0;
  float: right;

  /* submenu */
  .sub-menu {
    background-color: $color-white;
    display: none;
    position: absolute;
    font-size: 13px;
    border: 1px solid $color-main-header;
    border-top: 0;
    list-style-type: none;
    width: 300px;
    left: -25px;
    a {
      display: block;
      width: 100%;
      line-height: 25px;
      padding: 5px;
      &:hover {
        background-color: $color-main-header;
      }
    }
  }
}
#main-menu > li {
  display: inline-block;
  height: 52px;
  position: relative;
  &.has-sub-menu:hover > a {
    border-bottom: 1px solid $color-white;
    border-top: 5px solid $color-first;/*#0cbbc6, #27e6f2, #c1437a, #C3ECEE, #ECCDB3*/
  }
}
#main-menu > li > a {
  box-sizing: content-box;
  border-left: 1px solid $color-white;
  border-right: 1px solid $color-white;
  border-top: 5px solid $color-white;
  height: 32px;
}
#main-menu > li.selected > a,
#main-menu > li:hover > a {
  border-left: 1px solid $color-main-header;
  border-right: 1px solid $color-main-header;
  border-top: 5px solid $color-first;
}
#main-menu > li > a {
  display: block;
  height: 32px;
  color: $color-font-main;
  font-size: 14px;
  text-decoration: none;
  padding: 15px 18px 0 18px;
  text-transform: uppercase;
}
#main-menu > li.selected > a {
  font-weight: bold;
}
#main-menu > li:hover .sub-menu {
  display: block;
}

/* slicknav menu */
.slicknav_menu {
  display: none;
  background-color: $color-second;
}
@media screen and (max-width: 45em) {
  /* #menu is the original menu */
  #main-header {
    display: none;
  }
  #header-background {
    margin-top: 0;
  }
  .slicknav_menu {
    display: block;
  }
}
@media screen and (min-width: 45em) {
  #header-background {
    margin-top: 52px;
  }
}

/* header background */
#header-background {
  position: relative;
  height: 400px;
  background-position: 50% 0;
  .header-title,
  .header-sub-title {
    background-color: $color-second;
    color: $color-white;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    -khtml-opacity: 0.8;
    opacity: .8;
    padding: 10px;
    float: left;
    clear: both;
  }
  .header-title {
    font-size: 40px;
    margin-top: 232px;
  }
  .header-sub-title {
    font-size: 30px;
    margin-top: 10px;
  }
}

/* background switch */
#background-switch {
  position: absolute;
  left: 0;
  right: 10px;
  text-align: right;
  bottom: 10px;
  div {
    display: inline-block;
    border: 1px solid $color-white;
    width: 12px;
    height: 12px;
    margin-right: 3px;
    cursor: pointer;
    background-color: $color-white;
    @include border-radius(5px);
    &.current {
      background-color: $color-second;
      border-color: $color-second;
    }
  }
}

/* index */
.location-info {
  margin: 20px 0;
  text-align: center;
  font-weight: bold;
}
.products-info {
  margin: 20px 0;
  text-align: center;
  .product-info-list {
    width: 250px;
    margin: 0 auto;
    ul {
      margin-top: 5px;
      list-style-type: disc;
      text-align: left;
    }
  }
}

.product-category-list {
  .product-category {
    margin-bottom: 40px;
    a.image-link {
      -webkit-backface-visibility: hidden;
      overflow: hidden;
      display: block;
      width: 300px;
      height: 300px;
      -webkit-perspective: 1000;
      -moz-perspective: 1000;
      -ms-perspective: 1000;
      -o-perspective: 1000;
      border: 12px solid $color-main-header;
      @include border-radius(150px);
    }
    img {
      @include transition(all .5s ease);
    }
    a:not(.image-link) {
      display: block;
      width: 300px;
      text-align: center;
      line-height: 30px;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .product-category:hover {
    img {
      @include transform-scale(1.2);
    }
  }
}

/* special offers */
.special-offers {
  background-color: $color-second;
  color: $color-white;
  margin-top: 40px;
  .col-md-4 {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .title {
    font-size: 30px;
    margin: 20px 0 10px 0;
  }
  .info {
    font-size: 15px;
    .courier {
      font-size: 0.8em;
      margin-top: 10px;
    }
  }
}

/* footer */
#main-footer {
  background-color: $color-dark-light;
  color: $color-white;
  .col-md-3 {
    margin-top: 60px;
    margin-bottom: 60px;
    border-left: 1px solid #888;
    min-height: 120px;
  }
  .col-md-3:last-child {
    border-right: none;
  }
  .col-md-3 {
    strong {
      font-size: 30px;
    }
  }
  .col-md-3 {
    .info {
      font-size: 15px;
      margin-top: 30px;
    }
  }
  .col-md-3.society {
    .info {
      margin-top: 25px;
    }
  }
}
#footer-map-canvas {
  width: 100%;
  height: 350px;
  color: $color-dark-light;
}
#footer-bottom {
  background-color: #000;
  .col-md-12 {
    text-align: right;
    line-height: 50px;
  }
}

.row.with-margin-bottom {
  margin-bottom: 50px;
}

/* product list in category */
.product-list {
  margin-bottom: 20px;
  .product {
    margin: 20px 0;
    &:after {
      height: 20px;
      width: 100%;
      border-bottom: 1px solid $color-line-border;
    }
    .product-info {
      .title {
        font-size: 1.1em;
        margin-bottom: 10px;
        &::first-letter {
          text-transform: uppercase;
        }
      }
      .price {
        font-size: 1.6em;
        color: $color-second;
        margin-bottom: 20px;
      }
      .promotion-text {
        font-weight: bold;
        text-decoration: underline;
        margin-bottom: 20px;
      }

      .view-details {
        margin-top: 10px;
        text-decoration: underline;
      }
    }
    &.is-sold {
      .price {
        b {
          text-decoration: line-through;
          font-weight: bold;
        }
        .is-sold-info {
          text-decoration: none;
          margin-left: 20px;
          font-size: 0.8em;
        }
      }
    }
    .product-photo {
      text-align: right;
      .transition-container {
        .is-sold-image {
          position: absolute;
          right: 0;
        }
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    &:last-child:after {
      border-bottom: none;
    }
  }
}

.photo-with-transition {
  font-size: 0;
  line-height: 0;
  position: relative;
  img {
    @include transition(all .8s linear);
    @include transform-scale(1);
  }
  .transition-container {
    position: relative;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    img {
      opacity: 0.99;
    }
    &:before,
    &:after {
      content: "";
      opacity: 0;
      pointer-events: none;
      z-index: 3;
      position: absolute;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      @include transition(all .8s ease);
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
    &:before {
      top: 0;
      left: 0;
      right: 100%;
      bottom: 100%;
      border-top: 10px solid $color-second;
      border-left: 10px solid $color-second;
    }
    &:after {
      left: 100%;
      top: 100%;
      bottom: 0;
      right: 0;
      border-bottom: 10px solid $color-second;
      border-right: 10px solid $color-second;
    }
    &:hover {
      img:first-child {
        @include transform-scale(1.1);
        opacity: 1 !important;
      }
      &:before {
        opacity: 1;
        bottom: 0;
        right: 0;
      }
      &:after {
        opacity: 1;
        top: 0;
        left: 0;
      }
    }
  }
}

.gallery-photo {
  margin-bottom: 30px;
}

/* contact form */
#contact {
  .contact-info {
    margin-bottom: 20px;
    p {
      margin: 0;
      font-weight: bold;
    }
  }
  h2 {
    border-bottom: 1px solid $color-line-border;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
  #contact-map {
    width: 100%;
    height: 300px;
  }
  .contact-house {
    margin-bottom: 30px;
  }
  .contact-house, .contact-map {
    text-align: center;
  }
}

/* renovation */
.renovation-info {
  margin: 0 0 20px 0;
  text-align: center;
}
